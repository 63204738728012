import React from 'react';
import './nav.css';
import { AiOutlineHome } from 'react-icons/ai';
import { AiOutlineUser } from 'react-icons/ai';
import { BiBook } from 'react-icons/bi';
import { BiMessageSquareDetail } from 'react-icons/bi';

const Nav = ({ activeNav }) => {
	return (
		<nav>
			<a href="#home" className={activeNav.home ? 'active' : ''}>
				<AiOutlineHome />
			</a>
			<a href="#about" className={activeNav.about ? 'active' : ''}>
				<AiOutlineUser />
			</a>
			<a href="#experience" className={activeNav.experience ? 'active' : ''}>
				<BiBook />
			</a>
			<a href="#contact" className={activeNav.contact ? 'active' : ''}>
				<BiMessageSquareDetail />
			</a>
		</nav>
	);
};

export default Nav;
