import React from 'react';
import './footer.css';
import { FaFacebookF } from 'react-icons/fa';
import { FiInstagram } from 'react-icons/fi';
import { IoLogoTwitter } from 'react-icons/io';

const Footer = ({ handleChangeLanguage, currentLanguage, translation }) => {
	return (
		<footer>
			<a href="#home" className="footer__logo">
				GTiberi
			</a>
			<ul className="permalinks">
				<li>
					<a href="#home">{translation('footer_home')}</a>
				</li>
				<li>
					<a href="#about">{translation('footer_about')}</a>
				</li>
				<li>
					<a href="#experience">{translation('footer_experience')}</a>
				</li>
				{/* <li> */}
				{/* <a href="#portfolio">{translation('footer_portfolio')}</a> */}
				{/* </li> */}
				<li>
					<a
						href="#home"
						onClick={() => {
							handleChangeLanguage(currentLanguage.code === 'fr' ? 'en' : 'fr');
						}}
					>
						{translation('footer_language')}
					</a>
				</li>
			</ul>
			<div className="footer__socials">
				<a href="https://www.facebook.com/gabriel.l.tiberi">
					<FaFacebookF />
				</a>
				<a href="https://www.instagram.com/gabtiberi/">
					<FiInstagram />
				</a>
				<a href="https://twitter.com/TiberiGabriel">
					<IoLogoTwitter />
				</a>
			</div>
		</footer>
	);
};

export default Footer;
