import React, { useRef } from 'react';
import emailjs from 'emailjs-com';

const ContactForm = ({ translation }) => {
	const form = useRef();

	const sendEmail = e => {
		e.preventDefault();

		emailjs.sendForm(
			'service_fgo2y1k',
			'template_lk0jza3',
			form.current,
			'FdQ4BS5HH-ry_Od6T'
		);
		e.target.reset();
	};

	return (
		<form ref={form} onSubmit={sendEmail}>
			<input
				type="text"
				name="name"
				placeholder={translation('contactForm_placeholder1')}
				required
			/>
			<input
				type="email"
				name="email"
				placeholder={translation('contactForm_placeholder2')}
				required
			/>
			<textarea
				name="message"
				rows={7}
				placeholder={translation('contactForm_placeholder3')}
				required
			></textarea>
			<button type="submit" className="btn btn-primary">
				{translation('contactForm_message')}
			</button>
		</form>
	);
};

export default ContactForm;
