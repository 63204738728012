import React from 'react';
import ExpSkill from './ExpSkill';

const ExpContainer = ({ expSkills, title }) => {
	const skillset = expSkills ? (
		expSkills.map(expSkill => {
			return (
				<ExpSkill
					key={expSkill.skill}
					skill={expSkill.skill}
					exp={expSkill.exp}
				/>
			);
		})
	) : (
		<ExpSkill skill={'Empty'} exp={'Empty'} />
	);
	return (
		<div>
			<h3>{title}</h3>
			<div className="experience__content">{skillset}</div>
		</div>
	);
};

export default ExpContainer;
