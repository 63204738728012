import React, { useEffect } from 'react';
import { useInView } from 'react-intersection-observer';
import './contact.css';
import { MdOutlineEmail } from 'react-icons/md';
import { FiLinkedin } from 'react-icons/fi';
import ContactForm from './ContactForm';
import ContactOption from './ContactOption';

const Contact = ({ handleNav, translation }) => {
	const { ref: contactRef, inView } = useInView();
	useEffect(() => {
		if (inView) {
			handleNav('contact');
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [inView]);

	return (
		<section ref={contactRef} id="contact">
			<h5>{translation('contact_intro')}</h5>
			<h2>{translation('contact_title')}</h2>

			<div className="container contact__container">
				<div className="contact__options">
					<ContactOption
						icon={<MdOutlineEmail className="contact__option-icon" />}
						contactWay={'Email'}
						contactInfo={'gabriel.ltiberi@gmail.com'}
						contactLink={'mailto:gabriel.ltiberi@gmail.com'}
						message={translation('contactInfo1_message')}
					/>
					<ContactOption
						icon={<FiLinkedin className="contact__option-icon" />}
						contactWay={'LinkedIn'}
						contactInfo={'Gabriel Tiberi'}
						contactLink={
							'https://www.linkedin.com/in/gabriel-tiberi-99a37919a/'
						}
						message={translation('contactInfo2_message')}
					/>
				</div>
				<ContactForm translation={translation} />
			</div>
		</section>
	);
};

export default Contact;
