import React from 'react';

const ContactOption = ({
	icon,
	contactWay,
	contactInfo,
	contactLink,
	message,
}) => {
	return (
		<article className="contact__option">
			{icon}
			<h4>{contactWay}</h4>
			<h5>{contactInfo}</h5>
			<a href={contactLink} rel="noopener noreferrer" target="_blank">
				{message}
			</a>
		</article>
	);
};

export default ContactOption;
