import React, { useEffect } from 'react';
import { useInView } from 'react-intersection-observer';
import './about.css';
import ME from '../../assets/about2.jpg';
import { FaAward, FaRegHandshake } from 'react-icons/fa';
import { VscFolderLibrary } from 'react-icons/vsc';
import AboutCard from './AboutCard';

const About = ({ handleNav, translation }) => {
	const { ref: aboutRef, inView } = useInView();
	useEffect(() => {
		if (inView) {
			handleNav('about');
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [inView]);

	return (
		<section ref={aboutRef} id="about">
			<h5>{translation('about_intro')}</h5>
			<h2>{translation('about_title')}</h2>

			<div className="container about__container">
				<div className="about__me">
					<div className="about__me-image">
						<img src={ME} alt="Me" />
					</div>
				</div>
				<div className="about__content">
					<div className="about__cards">
						<AboutCard
							icon={<FaAward className="about__icon" />}
							title={translation('aboutCard1_title')}
							text={translation('aboutCard1_text')}
						/>
						<AboutCard
							icon={<FaRegHandshake className="about__icon" />}
							title={translation('aboutCard2_title')}
							text={translation('aboutCard2_text')}
						/>
						<AboutCard
							icon={<VscFolderLibrary className="about__icon" />}
							title={translation('aboutCard3_title')}
							text={translation('aboutCard3_text')}
						/>
					</div>
					<p>{translation('about_description')}</p>
					<a href="#contact" className="btn btn-primary">
						{translation('about_contact')}
					</a>
				</div>
			</div>
		</section>
	);
};

export default About;
