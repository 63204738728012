import React from 'react';
import { BsLinkedin } from 'react-icons/bs';
import { FaGithub } from 'react-icons/fa';
import { FiTwitter } from 'react-icons/fi';

const HeaderSocials = () => {
	return (
		<div className="header__socials">
			<a
				rel="noopener noreferrer"
				href="https://www.linkedin.com/in/gabriel-tiberi-99a37919a"
				target="_blank"
			>
				<BsLinkedin />
			</a>

			<a
				href="https://github.com/GTiberi?tab=repositories"
				rel="noopener noreferrer"
				target="_blank"
			>
				<FaGithub />
			</a>

			<a
				href="https://twitter.com/TiberiGabriel"
				rel="noopener noreferrer"
				target="_blank"
			>
				<FiTwitter />
			</a>
		</div>
	);
};

export default HeaderSocials;
