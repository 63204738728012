import React, { useEffect } from 'react';
import './header.css';
import CTA from './CTA';
import ME from '../../assets/me2.png';
import HeaderSocials from './HeaderSocials';
import { useInView } from 'react-intersection-observer';

const Header = ({ handleNav, translation }) => {
	const { ref: homeRef, inView } = useInView();

	useEffect(() => {
		if (inView) {
			handleNav('home');
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [inView]);
	return (
		<header ref={homeRef} id="home">
			<div className="container header__container">
				<h5>{translation('header_intro')}</h5>
				<h1>Gabriel Tiberi</h1>
				<h5 className="text-light">{translation('header_jobTitle')}</h5>
				<CTA translation={translation} />
				<HeaderSocials />

				<div className="me">{/* <img src={ME} alt="me" /> */}</div>

				<a href="#contact" className="scroll__down">
					{translation('header_scroll')}
				</a>
			</div>
		</header>
	);
};

export default Header;
