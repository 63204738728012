import React from 'react';
// @ts-ignore
import enCV from '../../assets/Gabriel_Lebeau-Tiberi_-_Backend_Developer.pdf';
// @ts-ignore
import frCV from '../../assets/Gabriel_Lebeau-Tiberi_-_Développeur_Backend.pdf';

import cookies from 'js-cookie';

const CTA = ({ translation }) => {
	const currentLanguageCode = cookies.get('i18next') || 'en';
	return (
		<div className="cta">
			<a
				href={currentLanguageCode === 'en' ? enCV : frCV}
				download
				className="btn"
			>
				{translation('CTA_download')}
			</a>
			<a href="#contact" className="btn btn-primary">
				{translation('CTA_contact')}
			</a>
		</div>
	);
};

export default CTA;
