import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import cookies from 'js-cookie';

import Header from './components/header/Header';
import Nav from './layout/Nav';
import About from './components/about/About';
import Footer from './components/footer/Footer';
import Experience from './components/experience/Experience';
// import Portfolio from './components/portfolio/Portfolio';
import Contact from './components/contact/Contact';
import i18next from 'i18next';

const languages = [
	{
		code: 'fr',
		name: 'Français',
		country_code: 'fr',
	},
	{
		code: 'en',
		name: 'English',
		country_code: 'gb',
	},
];

const App = () => {
	const [activeNav, setActiveNav] = useState({
		home: false,
		about: false,
		experience: false,
		contact: false,
	});

	const handleChangeLanguage = code => {
		i18next.changeLanguage(code);
	};

	const currentLanguageCode = cookies.get('i18next') || 'en';
	const currentLanguage = languages.find(l => l.code === currentLanguageCode);
	const { t } = useTranslation();

	useEffect(() => {
		document.title = t('app_title');
	}, [currentLanguage, t]);

	const handleNav = currentNav => {
		const navObj = {
			home: false,
			about: false,
			experience: false,
			contact: false,
		};
		if (currentNav !== '') {
			navObj[currentNav] = true;
		}

		setActiveNav(navObj);
	};
	return (
		<>
			<Header translation={t} handleNav={handleNav} />
			<Nav activeNav={activeNav} />
			<About translation={t} handleNav={handleNav} />
			<Experience translation={t} handleNav={handleNav} />
			{/* <Portfolio translation={t} /> */}
			<Contact translation={t} handleNav={handleNav} />
			<Footer
				translation={t}
				handleChangeLanguage={handleChangeLanguage}
				currentLanguage={currentLanguage}
			/>
		</>
	);
};

export default App;
