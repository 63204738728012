import React, { useEffect } from 'react';
import { useInView } from 'react-intersection-observer';
import './experience.css';
import ExpContainer from './ExpContainer';

const Experience = ({ handleNav, translation }) => {
	const { ref: experienceRef, inView } = useInView();
	useEffect(() => {
		if (inView) {
			handleNav('experience');
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [inView]);

	const frontendSkills = [
		{ skill: 'HTML', exp: translation('experience_level2') },
		{ skill: 'CSS', exp: translation('experience_level1') },
		{ skill: 'JavaScript', exp: translation('experience_level2') },
		{ skill: 'ReactJS', exp: translation('experience_level2') },
		{ skill: 'BootStrap', exp: translation('experience_level1') },
	];

	const backendSkills = [
		{ skill: 'MongoDB', exp: translation('experience_level1') },
		{ skill: 'MySQL', exp: translation('experience_level2') },
		{ skill: 'Spring Boot', exp: translation('experience_level2') },
		{ skill: 'NodeJS', exp: translation('experience_level2') },
		{ skill: 'C#', exp: translation('experience_level2') },
		{ skill: 'GraphQL', exp: translation('experience_level1') },
		{ skill: 'Kafka', exp: translation('experience_level1') },
		{ skill: 'AWS', exp: translation('experience_level1') },
		{ skill: 'Jenkins', exp: translation('experience_level2') },
		{ skill: 'Kubernetes', exp: translation('experience_level2') },
		{ skill: 'Docker', exp: translation('experience_level2') },
	];
	return (
		<section ref={experienceRef} id="experience">
			<h5>{translation('experience_title')}</h5>
			<h2>{translation('experience_text')}</h2>

			<div className="container experience__container">
				<ExpContainer
					title={translation('expContainer1_title')}
					expSkills={frontendSkills}
				/>
				<ExpContainer
					title={translation('expContainer2_title')}
					expSkills={backendSkills}
				/>
			</div>
		</section>
	);
};

export default Experience;
